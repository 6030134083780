import store from '@/store';
import router from '@/router'; 

const isAuthenticatedGuard = async (to, from, next) => {
	try {
		const { ok } = await store.dispatch('auth/checkAuthentication');
		const { activities } = store.state.auth;
		const metas = to.matched.length > 1 ? to.matched[1]?.meta : {};

		if (!ok) {
			if (to.name !== 'Login') {
				router.push({ name: 'Login' });
			}
			return;
		}

		if (metas.moduleName && metas.pageName) {
			const foundActivity = activities.find(
				(activity) =>
					activity.module_name === metas.moduleName &&
					activity.page_name === metas.pageName
			);

			if (foundActivity) {
				return next();
			} else {
				router.push({ name: 'NotAuthorized' });
				return;
			}
		}

		return next();
	} catch (error) {
		router.push({ name: 'Login' });
	}
};

export default isAuthenticatedGuard;
